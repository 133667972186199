type footerItem = {
  name: string;
  href: string;
};
const footerItems: footerItem[] = [
  { name: 'Home', href: '/' },
  { name: 'Contact', href: '/contact.html' },
  { name: 'Mineral Liberation', href: '/minlib.html' },
  { name: 'Inclusion Classifier', href: '/inclusion.html' },
  { name: 'Slabs', href: '/slabs.html' },
  { name: 'About', href: '#' },
];
const Footer = (props: { current: string }) => {
  return (
    <footer className=" mt-4 bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-12 sm:py-12 lg:px-8">
        <nav
          className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          {footerItems.map((item) => (
            <div key={item.name} className="pb-6">
              <a
                href={item.href}
                className="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2023 Ottab Technologies. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
